@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.table-header {
  font-size: 0.80rem;
  text-transform: uppercase;
  font-weight: bold !important;
  /* padding-left: 20px !important; */
}

.custom-header-container {
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
}

.red-text {
  color: #ff0000;
}

/* 

.bold-text {
  padding-left: 20px !important;
  font-weight: 600;
  color: #364048;
}

.thin-text {
  padding-left: 20px !important;
  font-weight: 400;
  color: #52606d;
}

.custom-header-container .MuiDataGrid-columnHeaders {
  background-color: #eef2f6;
}

.logo {
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Red Hat Display', sans-serif;
  margin: 0px;
  color: #0061ff;
}

.close-panel-icon {
  filter: invert(33%) sepia(13%) saturate(661%) hue-rotate(169deg) brightness(95%) contrast(90%);
  width: 100%
} */