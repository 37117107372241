.custom-table {
    border-width: 0px !important;
    margin-top: 20px !important;
}

.custom-table .MuiDataGrid-toolbarContainer {
    margin: 15px 0px;
}

.custom-table .MuiDataGrid-columnHeaders {
    background-color: #f3f4f8 !important;
    border-width: 0px !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    padding: 5px;
    margin-bottom: 10px;
}

.custom-table .MuiDataGrid-columnHeaderTitle {
    font-size: 0.625rem;
    font-weight: 600;
    color: #4b5865;
}

.custom-table .MuiDataGrid-withBorderColor {
    border-color: transparent !important;
}

.custom-table .MuiDataGrid-virtualScroller {
    scrollbar-width: thin;
    scrollbar-color: #d5d5d5 transparent;
    /* For Firefox */
    overflow: hidden;
    /* Hide scrollbar when not in use */
}

/* For WebKit browsers (Chrome, Safari) */
.custom-table .MuiDataGrid-virtualScroller::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transition: opacity 0.3s ease;
    /* Add transition for opacity */
    opacity: 0;
    /* Hide scrollbar when not in use */
}

.custom-table .MuiDataGrid-virtualScroller:hover {
    overflow: auto;
    /* Show scrollbar on hover */
}

.custom-table .MuiDataGrid-virtualScroller:hover::-webkit-scrollbar {
    opacity: 1;
    /* Show scrollbar on hover */
}

.custom-table .MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
    background: transparent;
    border: none;
}

.custom-table .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: none;
    transition: background-color 0.3s ease;
    /* Add transition for background color */
}

.custom-table .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.table-header {
    font-weight: bolder;
    padding-left: 15px !important;
}

.table-row {
    /* background-color: orange; */
    /* border: 1px solid green;
    border-radius: 8px;
    margin-bottom: 10px; */
    transition: 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
    border-radius: 10px;
    cursor: pointer;
}

.table-row:hover {
    background-color: #dcecf6 !important;
}

.table-cell {
    border-bottom: none !important;
    padding-left: 20px !important;
    text-transform: capitalize;
}

@media (max-width: 768px) {
    /* .custom-table .MuiDataGrid-columnHeaderTitle {
        font-size: 0.5rem;
    } */

    .table-cell {
        padding-left: 15px !important;
    }
}