@keyframes customFadeInUp {
    0% {
        opacity: 0;
        transform: translateY(15px);
        /* Shorter height */
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes customFadeInUpLonger {
    0% {
        opacity: 0;
        transform: translateY(30px);
        /* Shorter height */
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

p.animate__fadeInUp {
    animation: customFadeInUp 1.2s ease forwards;
}

img.animate__fadeInUp {
    animation: customFadeInUpLonger 1.7s ease forwards;
    /* animation-delay: 0.1s; */
}

.login-header-lg {
    display: block;
}

.login-header-sm {
    display: none;
}

.logo {
    font-family: 'Noto Sans', sans-serif;
    font-family: 'Red Hat Display', sans-serif;
    color: black;
    margin-bottom: 20px;
}

.slide-indicators {
    margin-top: 50px;
    display: inline-block;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.slide-indicator {
    background-color: #b6b6b6;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: flex;
    margin-left: 8px;
    transition: background-color 200ms;
}

.slide-indicator.active {
    background-color: black;
}

.tutorial-item-wrapper:last-child {
    border-bottom: none;
}

@media (max-width: 768px) {
    .login-header-lg {
        display: none;
    }

    .login-header-sm {
        display: block;
    }
}